import React, { useState } from "react";
import message from "../assets/icons/message.png";
import location from "../assets/icons/location.png";
import call from "../assets/icons/call.png";
import circle from "../assets/images/homepage-9.png";
import "../assets/images/homepage-9.png";
import "../styles/demo.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Demo = () => {
  const [registerInput, setregister] = useState({
    Nombre: "",
    Apellido: "",
    Telefono: "",
    Email: "",
    error_list: [],
  });

  const handleInput = (e) => {
    setregister({ ...registerInput, [e.target.name]: e.target.value });
  };

  const registerSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();

    data.append("Nombre", registerInput.Nombre);
    data.append("Apellido", registerInput.Apellido);
    data.append("Email", registerInput.Email);
    data.append("Telefono", registerInput.Telefono);

    await axios.post("api/create/demo", data).then((res) => {
      if (res.data.status === 200) {
        swal({
          title: "Solicitud enviada",
          text: "Le llegara un correo con las claves de acceso",
          button: "Continuar",
        });
        setregister({
          Nombre: "",
          Apellido: "",
          Email: "",
          Telefono: "",
        });
      } else {
        setregister({
          ...registerInput,
          error_list: res.data.validation_errors,
        });
      }
    });
  };

  return (
    <>
      <section className="s4-demo">
        <div className="s4-title">
          <h2>¡Prueba nuestra demo!</h2>
          <h3>
            Rellena el formulario y te enviamos un email con el usuario y
            contraseña.
          </h3>
        </div>
        <div className="s4-cont">
          <div className="form-container">
            <div className="demo-left">
              <div className="demo-left-title">
                <h2>
                  Rellena el formulario y te enviamos un email con el usuario y
                  contraseña.
                </h2>
              </div>
              <div className="demo-left-details"></div>
              <img src={circle} className="circle-img" alt="circle" />
            </div>
            <div className="demo-right">
              <form onSubmit={registerSubmit}>
                <div className="demo-names">
                  <div className="demo-name">
                    <h2>Nombre</h2>
                    <input
                      type="text"
                      name="Nombre"
                      id=""
                      placeholder="Nombre"
                      onChange={handleInput}
                      value={registerInput.Nombre}
                    />
                  </div>
                  <div className="demo-name">
                    <h2>Apellidos</h2>
                    <input
                      type="text"
                      name="Apellido"
                      id=""
                      placeholder="Apellidos"
                      onChange={handleInput}
                      value={registerInput.Apellido}
                    />
                  </div>
                </div>
                <div className="demo-email">
                  <h2>Email</h2>
                  <input
                    type="email"
                    name="Email"
                    placeholder="Email"
                    id=""
                    onChange={handleInput}
                    value={registerInput.Email}
                  />
                </div>
                <div className="demo-phone">
                  <h2>Teléfono</h2>
                  <input
                    type="phone"
                    name="Telefono"
                    placeholder="Teléfono"
                    onChange={handleInput}
                    value={registerInput.Telefono}
                    id=""
                  />
                </div>
                <div className="demo-names" id="demo-button">
                  <div className="bottom">
                    <div className="check-marketing">
                      <input
                        type="checkbox"
                        required
                        id="scales"
                        name="scales"
                      />
                      <label for="scales" className="label-marketing">
                        He leído y acepto la{" "}
                        <Link to="/Privacy-policy">
                          <b className="politica">política de privacidad</b>
                        </Link>.
                      </label>
                    </div>
                    <button
                      href="https://demo.wedibo.es/"
                      type="submit"
                      target="_blank"
                      className="Demo"
                    >
                      <h4 >Prueba nuestra demo</h4>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Demo;
