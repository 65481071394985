import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../styles/navbar.css";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import profile from '../assets/icons/user.png';

const Navbar = () => {
  const history = useHistory();

  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function getUsers() {
      try {
        const res = await axios.get("/api/current/user");
        if (res.data.status === 200) {
          setUsers(res.data.users);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // El usuario no está autenticado, puedes manejar este caso según tus necesidades
          // Por ejemplo, puedes redirigir al usuario a la página de inicio de sesión
          console.log("Usuario no autenticado");
        } else {
          // Otro tipo de error, puedes manejarlo de acuerdo a tus necesidades
          console.log("Error al obtener los usuarios", error);
        }
      }
    }

    getUsers();
  }, []);

  const juridica = localStorage.getItem("tipo") === "1";
  const fisica = localStorage.getItem("tipo") === 2;

  const auth = localStorage.getItem("auth_name");
  const handleSignOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("auth_name");
    localStorage.removeItem("auth_token");
    history.push("/login");
  };
  return (
    <>
      <nav className="navbar">
        {auth ? (
          <ul className="navbar-ul">
            <li className="navbar-li">
              <NavLink to="/" activeClassName="active" exact>
                Inicio
              </NavLink>
            </li>

            <li className="navbar-li">
              <NavLink to="/Tutoriales" activeClassName="active" exact>
                Tutoriales
              </NavLink>
            </li>
            <li className="navbar-li">
              <NavLink to="/About" activeClassName="active" exact>
                Conócenos
              </NavLink>
            </li>
            <li className="navbar-li">
              <NavLink to="/Contact" activeClassName="active" exact>
                Contacto
              </NavLink>
            </li>
            <li className="navbar-li" id="dropdown">
              <div className="profile-icon" id="profile-img">
                {users.file ? (
                  <img
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      `/storage/${users.file}`
                    }
                    alt="user-foto"
                  />
                ) : (
                  <img src={profile} alt="user-foto" />
                )}{" "}
              </div>
              <aside className="dropdown-menus" id="drop-link">
                {users.username}
                <hr />

                <li className="navbar-li">
                  {juridica ? (
                    <li className="navbar-li">
                      <NavLink to="/user/profile/juridica" id="drop-link" exact>
                        Perfil
                      </NavLink>
                    </li>
                  ) : (
                    <li className="navbar-li">
                      <NavLink to="/user/profile/fisica" id="drop-link" exact>
                        Perfil
                      </NavLink>
                    </li>
                  )}
                </li>
                <li className="navbar-li">
                  <NavLink
                    to="/Login"
                    className="link"
                    id="drop-link"
                    onClick={handleSignOut}
                  >
                    Cerrar sesión
                  </NavLink>
                </li>
              </aside>
            </li>
          </ul>
        ) : (
          <ul className="navbar-ul">
            <li className="navbar-li">
              <NavLink to="/" activeClassName="active" exact>
                Inicio
              </NavLink>
            </li>
            <li className="navbar-li">
              <NavLink to="/About" activeClassName="active" exact>
                Conócenos
              </NavLink>
            </li>
            <li className="navbar-li">
              <NavLink to="/Contact" activeClassName="active" exact>
                Contacto
              </NavLink>
            </li>
            <li className="navbar-li">
              <NavLink
                to="/Login"
                className="link"
                id="login"
                onClick={handleSignOut}
              >
                Inicia sesión
              </NavLink>
            </li>
          </ul>
        )}
      </nav>
    </>
  );
};

export default Navbar;
