import axios from 'axios';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import '../styles/newpass.css';


const NewPassword = () => {

    const history = useHistory();

    const  {token}  = useParams();

    console.log(token);



    const [registerInput, setRegister] = useState({
        email: "",
        password: "",
        password_confirmation: "",
        error_list: [],
    });

    const handleInput = (e) => {
        setRegister({ ...registerInput, [e.target.name]: e.target.value });
    }



    const resetPassword = async (e) => {


        e.preventDefault();

        const data = new FormData();


        data.append("email", registerInput.email);
        data.append("password", registerInput.password);
        data.append("password_confirmation", registerInput.password_confirmation);
        data.append("token",token );


        await axios.post(process.env.REACT_APP_PUBLIC_URL+'/api/new/password', data, { headers: { Accept: 'application/json' } }).then(res => {
            console.log(res);
            console.log(res.data);

            if (res.status === 200) {
                swal({
                    title: "Contraseña cambiada con éxito",
                    text: 'Ya puedes acceder con tu usuario y tu nueva contraseña',
                    icon: "success",
                    button: "Continuar",
                });
                history.push('/login')
                console.log(res);

                setRegister({
                    email: '',
                    password: '',
                    password_confirmation: '',

                });
            }

        });

    }




    return (
        <>
            <section className="newpass-container">
                <div className="email-newpass">
                    <form onSubmit={resetPassword}>
                        <h2>Ingresa tu nueva contraseña</h2>
                        <input className='email' type="text" name="email" onChange={handleInput} value={registerInput.email} id="" placeholder="Email" />
                        <input className='email' type="password" name="password" onChange={handleInput} value={registerInput.password} id="" placeholder="Nueva contraseña" />
                        <input className='email' type="password" name="password_confirmation" onChange={handleInput} value={registerInput.password_confirmation} id="" placeholder="Confirma la contraseña" />
                        <input className='submit' type="submit" value="Enviar" />
                    </form>
                </div>
            </section>
        </>
    )
}

export default NewPassword;