import React from "react";
import "../styles/switch-button.css"
const Switch = () => {

    const toggleForm = () => {
        let divForm1 = document.getElementById('s-form-id');
        let divForm2 = document.getElementById('s-form-id-1');
        divForm1.classList.toggle('display-s');
        divForm2.classList.toggle('display-n');
    }

    return (
        <>
            <div class="switch-button" onClick={toggleForm}>
                <input class="switch-button-checkbox" type="checkbox"></input>
                <label class="switch-button-label" for=""><span class="switch-button-label-span">Juridica</span></label>
            </div>
        </>
    )

}

export default Switch;