import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import swal from "sweetalert";
import '../../styles/register-juridica.css';
import cros from '../../assets/icons/croses.png';




function RegisterJuridicas() {


    const [users, setUsers] = useState([]);

    const history = useHistory()


    const [registerInput, setRegister] = useState({
        Cif: "",
        Id_Usuario: "",
        Nombre_Fiscal: "",
        Nombre_Administrador: "",
        Dni: "",
        Domicilio_Fiscal: "",
        Telefono_Fijo: "",
        Telefono_Movil: "",
        Email: "",
        Tipo_Empresa: "",
        Numero_Usuarios: "",
        Pais: "",
        Provincia: "",
        Domicilio: "",
        Codigo_Postal: "",
        Ciudad: "",
        Subdominio: "",
        Forma_Pago: "",
        Nombre_Tarjeta: "",
        Numero_Tarjeta: "",
        CVV_Tarjeta: "",
        Caducidad_Suscripcion: "",
        error_list: [],
      });



    useEffect(() => {
        async function users() {
            const res = await axios.get('/api/users/index');
            if (res.data.status === 200) {
                setUsers(res.data.users)
            }
        }
        users();
    }, [])


    const handleInput = (e) => {
        setRegister({ ...registerInput, [e.target.name]: e.target.value });
    }




    // const handleOption = (option) => {
    //     setOption('');
    // };

    // const handleImage = (files) => {
    //     setImagedata(files);
    // };

    // for (let i = 0; i < imagedata.length; i++)
    //     data.append(`imagenes[${i}]`, imagedata[i], imagedata[i].name)
    const registerSubmit = async (e) => {
        e.preventDefault();


        const data = new FormData();

        data.append("Cif", registerInput.Cif);
        data.append("Id_Usuario", registerInput.Id_Usuario);
        data.append("Nombre_Fiscal", registerInput.Nombre_Fiscal);
        data.append("Domicilio_Fiscal", registerInput.Domicilio_Fiscal);
        data.append("Nombre_Administrador", registerInput.Nombre_Administrador);
        data.append("Dni", registerInput.Dni);
        data.append("Telefono_Fijo", registerInput.Telefono_Fijo);
        data.append("Telefono_Movil", registerInput.Telefono_Movil);
        data.append("Email", registerInput.Email);
        data.append("Tipo_Empresa", registerInput.Tipo_Empresa);
        data.append("Numero_Usuarios", registerInput.Numero_Usuarios);
        data.append("Pais", registerInput.Pais);
        data.append("Provincia", registerInput.Provincia);
        data.append("Domicilio", registerInput.Domicilio);
        data.append("Codigo_Postal", registerInput.Codigo_Postal);
        data.append("Ciudad", registerInput.Ciudad);
        data.append("Subdominio", registerInput.Subdominio);
        // data.append("Forma_Pago", registerInput.Forma_Pago);
        // data.append("Nombre_Tarjeta", registerInput.Nombre_Tarjeta);
        // data.append("Numero_Tarjeta", registerInput.Numero_Tarjeta);
        // data.append("CCV_Tarjeta", registerInput.CVV_Tarjeta);
        // data.append("Caducidad_Suscripcion", registerInput.Caducidad_Suscripcion);




        await axios.post('/api/admin/create/legal/user', data).then(res => {

            if (res.data.status === 200) {

                swal({
                    title: "Buen trabajo !",
                    text: "Se ha registrado como persona juridica !",
                    icon: "success",
                    button: "Continuar...",
                });
                setRegister({
                    Cif: "",
                    Id_Usuario: "",
                    Nombre_Fiscal: "",
                    Nombre_Administrador: "",
                    Dni: "",
                    Domicilio_Fiscal: "",
                    Telefono_Fijo: "",
                    Telefono_Movil: "",
                    Email: "",
                    Tipo_Empresa: "",
                    Numero_Usuarios: "",
                    Pais: "",
                    Provincia: "",
                    Domicilio: "",
                    Codigo_Postal: "",
                    Ciudad: "",
                    Subdominio: "",
                    Forma_Pago: "",
                    Nombre_Tarjeta: "",
                    Numero_Tarjeta: "",
                    CVV_Tarjeta: "",
                    Caducidad_Suscripcion: "",
                });

                history.push('/admin/juridicas');

            }
            else {
                setRegister({ ...registerInput, error_list: res.data.validation_errors });
            }

        });



    }

    return (
        <>
            <section className="form-creates">
            <Link to="/admin/juridicas">
                    <img src={cros} alt="" id="croses-ad" />
                </Link>
                <div className="contact-forms" id="create-admin">
                    <form onSubmit={registerSubmit} className="form-house-create" id="create-admin-form">
                        <div className="form-lefts">
                            <div className="superficies">
                                <div className="superficie">
                                    <h2>CIF</h2>
                                    <input type="text" name="Cif" placeholder="#123456789" onChange={handleInput} value={registerInput.Cif} />

                                </div>
                                <div className="superficie">
                                    <h2>Nombre Fiscal</h2>
                                    <input type="text" name="Nombre_Fiscal" id="" placeholder="Nombre Fiscal" onChange={handleInput} value={registerInput.Nombre_Fiscal} />
                                </div>
                            </div>
                            <div className="superficies">
                                <div className="superficie">
                                    <h2>Domicilio Fiscal</h2>
                                    <input type="text" name="Domicilio_Fiscal" id="" placeholder="Su Domicilio " onChange={handleInput} value={registerInput.Domicilio_Fiscal} />
                                </div>
                                <div className="superficie">
                                    <h2>Tipo De Empresa</h2>
                                    <input type="text" name="Tipo_Empresa" id="" placeholder="Tipo Empresa" onChange={handleInput} value={registerInput.Tipo_Empresa} />
                                </div>
                            </div>
                            <div className="superficies">
                                <div className="superficie">
                                    <h2>Nombre Administrador</h2>
                                    <input type="text" name="Nombre_Administrador" id="" placeholder="Nombre Del Administrador" onChange={handleInput} value={registerInput.Nombre_Administrador} />
                                </div>
                                <div className="superficie">
                                    <h2>DNI</h2>
                                    <input type="text" name="Dni" id="" placeholder="Su DNI" onChange={handleInput} value={registerInput.Dni} />
                                </div>
                            </div>
                            <div className="superficies">
                                <div className="superficie">
                                    <h2>Telefono Fijo</h2>
                                    <input type="text" name="Telefono_Fijo" id="" placeholder="Su Fijo" onChange={handleInput} value={registerInput.Telefono_Fijo} />
                                </div>
                                <div className="superficie">
                                    <h2>Telefono Movil</h2>
                                    <input type="text" name="Telefono_Movil" id="" placeholder="Su Movil" onChange={handleInput} value={registerInput.Telefono_Movil} />
                                </div>
                            </div>

                            <div className="superficies">

                                <div className="superficie">
                                    <h2>Email</h2>
                                    <input type="email" name="Email" id="" placeholder="Email" onChange={handleInput} value={registerInput.Email}/>
                                </div>
                                <div className="superficie">
                                    <h2 id='precio-ad'>Numero De Clientes</h2>
                                    <input type="text" name="Numero_Usuarios" placeholder="N° Clientes" onChange={handleInput} value={registerInput.Numero_Usuarios} />
                                </div>

                            </div>






                        </div>
                        <div className="form-lefts">
                            <div className="superficies">

                                <div className="superficie">
                                    <h2>Pais</h2>
                                    <input type="text" name="Pais" id="" placeholder="Su Email" onChange={handleInput} value={registerInput.Pais} />
                                </div>
                                <div className="superficie">
                                    <h2 id='precio-ad'>Provincia</h2>
                                    <input type="text" name="Provincia" placeholder="Provincia" onChange={handleInput} value={registerInput.Provincia} />
                                </div>

                            </div>
                            <div className="superficies">

                                <div className="superficie">
                                    <h2>Domicilio</h2>
                                    <input type="text" name="Domicilio" id="" placeholder="Domicilio" onChange={handleInput} value={registerInput.Domicilio}/>
                                </div>
                                <div className="superficie">
                                    <h2 id='precio-ad'>Ciudad</h2>
                                    <input type="text" name="Ciudad" placeholder="Ciudad" onChange={handleInput} value={registerInput.Ciudad} />
                                </div>

                            </div>

                            <div className="superficies">

                                <div className="superficie">
                                    <h2>Codigo Postal</h2>
                                    <input type="text" name="Codigo_Postal" id="" placeholder="18001" onChange={handleInput} value={registerInput.Codigo_Postal} />
                                </div>
                             



                            </div>

                            <div className="superficies">


                                <div className="superficie">
                                    <h2 id='precio-ad'>Subdominio Deseado</h2>
                                    <input type="text" name="Subdominio" placeholder="SI/NO" onChange={handleInput} value={registerInput.Subdominio} />
                                </div>

                                <div className="superficie">
                                    <h2>Usuario</h2>
                                    <select name="Id_Usuario" onChange={handleInput} value={registerInput.Id_Usuario} id="select-vendedor">
                                        <option label="Elige al vendedor"></option>

                                        {users.map((item) => {
                                            return (
                                                <option key={item.id} value={item.id}>{item.username}</option>

                                            )
                                        })}
                                    </select>
                                </div>

                            </div>

                            <input type="submit" className="send-contact" id="admin-create-house-button" value="Añadir" />


                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default RegisterJuridicas;