import Accordion from 'react-bootstrap/Accordion';
import "../styles/acordeon.css";

function Acordeon() {
  return (
    <Accordion flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>¿Mi contrato con la aseguradora me lo permite?</Accordion.Header>
        <Accordion.Body>
          ¡Por supuesto! Tu contrato de exclusividad te impide el uso que no sea con el fin de la comercialización de otros productos.
        </Accordion.Body>
      </Accordion.Item>
      <div className="line-acordeon"></div>
      <Accordion.Item eventKey="1">
        <Accordion.Header>¿Y si tengo mi cartera en Excel?</Accordion.Header>
        <Accordion.Body>
          Con Excel solo tienes un ínfimo retorno de toda la información que podrías tener con WeDIBO.
        </Accordion.Body>
      </Accordion.Item>
      <div className="line-acordeon"></div>

      <Accordion.Item eventKey="2">
        <Accordion.Header>¿Por qué debo pagar?</Accordion.Header>
        <Accordion.Body>
        WeDIBO a diferencia de otros CRM del mercado es una base de datos viva, en constante crecimiento para ofrecer el mejor servicio y adaptarnos a tus necesidades.
        </Accordion.Body>
      </Accordion.Item>
      <div className="line-acordeon"></div>

      <Accordion.Item eventKey="3">
        <Accordion.Header>¿Y si mañana trabajo para otra compañía?</Accordion.Header>
        <Accordion.Body>
            En ese caso tienes el 90% de éxito asegurado, si tienes la Ley Oficial de Protección de Datos firmada a favor de tu empresa ya puedes empezar tu nueva andadura laboral.
        </Accordion.Body>
      </Accordion.Item>
      <div className="line-acordeon"></div>

    </Accordion>
  );
}

export default Acordeon;