import axios from "axios";
import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import swal from "sweetalert";
import '../../styles/register-juridica.css';
import cros from '../../assets/icons/croses.png';




function RegisterUsuarios() {


    const [imagedata, setImagedata] = useState("");
    const history = useHistory()

    const [registerInput, setRegister] = useState({
        username: "",
        email: "",
        password: "",
        confirmed: "",
        error_list: [],
    });

    const handleInput = (e) => {
        setRegister({ ...registerInput, [e.target.name]: e.target.value });
    }


    const handleImage = (file) => {
        setImagedata(file[0]);
    };



    // const handleOption = (option) => {
    //     setOption('');
    // };

    // const handleImage = (files) => {
    //     setImagedata(files);
    // };

    // for (let i = 0; i < imagedata.length; i++)
    //     data.append(`imagenes[${i}]`, imagedata[i], imagedata[i].name)

    const registerSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData();
        data.append("file", imagedata);
        data.append("username", registerInput.username);
        data.append("email", registerInput.email);
        data.append("password", registerInput.password);
        data.append("confirmed", registerInput.confirmed);
        
        await axios.post('/api/register', data).then(res => {
            
            console.log(data);

            if (res.data.status === 200) {
                swal({
                    title: "Completado !",
                    text: "Ha registrado un nuevo usuario !",
                    icon: "success",
                    button: "Continuar...",
                });
                history.push('/admin/usuarios');
                setRegister({
                    username: '',
                    email: '',
                    password: '',
                    confirmed: '',
                    file: '',
                });
            }
            else {
                setRegister({ ...registerInput, error_list: res.data.validation_errors });
            }

        });

    }

    return (
        <>
            <section className="form-creates">
            <Link to="/admin/usuarios">
                    <img src={cros} alt="" id="croses-ad" />
                </Link>
                <div className="contact-forms" id="create-admin">
                    <form onSubmit={registerSubmit} className="form-house-create" id="create-admin-form-usuario">
                        <div className="form-lefts" id="form-lefts-admin">
                            <div className="superficies">
                                <div className="superficie">
                                    <h2>Nombre Usuario</h2>
                                    <input type="text" placeholder="Usuario" name='username' onChange={handleInput} value={registerInput.username} />
                                    <span className="text-danger">{registerInput.error_list.username}</span>

                                </div>
                                <div className="superficie">
                                    <h2>Email</h2>
                                    <input type="text" placeholder="Correo Electronico" name='email' onChange={handleInput} value={registerInput.email} />
                                    <span className="text-danger">{registerInput.error_list.email}</span>

                                </div>
                            </div>

                            <div className="superficies">
                                <div className="superficie">
                                    <h2>Contraseña</h2>
                                   
                                 <input type="password" placeholder="Crea Una Contraseña" name='password' onChange={handleInput} value={registerInput.password} />
                                 <span className="text-danger">{registerInput.error_list.password}</span>

                                </div>
                                
                                <div className="superficie">
                                    <h2>Confirma Contraseña</h2>
                                    <input type="password" placeholder="Confirma Tu Contraseña" name='confirmed' onChange={handleInput} value={registerInput.confirmed} />
                                    <span className="text-danger">{registerInput.error_list.confirmed}</span>

                                </div>
                            </div>

                            <input type="file" name='file' id='image'  onChange={e => handleImage(e.target.files)} />




                            <input type="submit" className="send-contact" id="admin-create-usuario-button" value="Añadir" />


                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default RegisterUsuarios;