import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, Link} from "react-router-dom";
import swal from "sweetalert";
import '../../styles/register-juridica.css';
import cros from '../../assets/icons/croses.png';




function RegisterContacto() {


    const [sellers, setSellers] = useState([]);

    const history = useHistory()

    const [registerInput, setRegister] = useState({
        Contacto_Juridico: "",
        Nombre: "",
        DNI: "",
        Telefono_Fijo: "",
        Telefono_Movil: "",
        Email: "",
        Pais: "",
        Provincia: "",
        Domicilio: "",
        Codigo_Postal: "",
        Ciudad: "",
        error_list: [],
    });

    useEffect(() => {
        async function sellers() {
            const res = await axios.get('/api/juridicas/index');
            if (res.data.status === 200) {
                setSellers(res.data.juridicas)
            }
        }
        sellers();
    }, [])

    const handleInput = (e) => {
        setRegister({ ...registerInput, [e.target.name]: e.target.value });
    }

    // const handleOption = (option) => {
    //     setOption('');
    // };

    const registerSubmit = async (e) => {

        e.preventDefault();

        const data = new FormData();

        data.append("Contacto_Juridico", registerInput.Contacto_Juridico);
        data.append("Nombre", registerInput.Nombre);
        data.append("DNI", registerInput.DNI);
        data.append("Telefono_Fijo", registerInput.Telefono_Fijo);
        data.append("Telefono_Movil", registerInput.Telefono_Movil);
        data.append("Email", registerInput.Email);
        data.append("Pais", registerInput.Pais);
        data.append("Provincia", registerInput.Provincia);
        data.append("Domicilio", registerInput.Domicilio);
        data.append("Codigo_Postal", registerInput.Codigo_Postal);
        data.append("Ciudad", registerInput.Ciudad);

        await axios.post('api/admin/create/contacto/user', data).then(res => {

            if (res.data.status === 200) {

                swal({
                    title: "Buen trabajo !",
                    text: "Se ha registrado como persona juridica !",
                    icon: "success",
                    button: "Continuar...",
                });
                setRegister({
                    Contacto_Juridico: "",
                    Nombre: "",
                    DNI: "",
                    Telefono_Fijo: "",
                    Telefono_Movil: "",
                    Email: "",
                    Ciudad: "",
                    Pais: "",
                    Provincia: "",
                    Domicilio: "",
                    Codigo_Postal: "",
                });

                history.push('/admin/contactos');

            }
            else {
                setRegister({ ...registerInput, error_list: res.data.validation_errors });
            }

        });



    }

    return (
        <>
            <section className="form-creates">
            <Link to="/admin/contactos">
                    <img src={cros} alt="" id="croses-ad" />
                </Link>
                <div className="contact-forms" id="create-admin">
                    <form onSubmit={registerSubmit} className="form-house-create" id="create-admin-form">
                        <div className="form-lefts">
                            <div className="superficies">
                                <div className="superficie">
                                    <h2>Persona Juridica</h2>
                                    <select name="Contacto_Juridico" onChange={handleInput} value={registerInput.Contacto_Juridico} id="select-vendedor">
                                        <option label="Elige a la empresa"></option>

                                        {sellers.map((item) => {
                                            return (
                                                <option key={item.id} value={item.id}>{item.Nombre_Fiscal}</option>

                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="superficie">
                                    <h2>Email</h2>
                                    <input type="email" name="Email" id="" placeholder="Su Email" onChange={handleInput} value={registerInput.Email} />
                                </div>

                            </div>

                            <div className="superficies">
                                <div className="superficie">
                                    <h2>Telefono Fijo</h2>
                                    <input type="text" name="Telefono_Fijo" id="" placeholder="Su Fijo" onChange={handleInput} value={registerInput.Telefono_Fijo} />
                                </div>
                                <div className="superficie">
                                    <h2>Telefono Movil</h2>
                                    <input type="text" name="Telefono_Movil" id="" placeholder="Su Movil" onChange={handleInput} value={registerInput.Telefono_Movil} />
                                </div>
                            </div>

                            <div className="superficies">

                                <div className="superficie">
                                    <h2>Nombre</h2>
                                    <input type="text" name="Nombre" id="" placeholder="Su DNI" onChange={handleInput} value={registerInput.Nombre} />
                                </div>

                                <div className="superficie">
                                    <h2>DNI</h2>
                                    <input type="text" name="DNI" id="" placeholder="Su DNI" onChange={handleInput} value={registerInput.DNI} />
                                </div>

                            </div>






                        </div>
                        <div className="form-lefts">
                            <div className="superficies">

                                <div className="superficie">
                                    <h2>Pais</h2>
                                    <input type="texto" name="Pais" id="" placeholder="Su Pais" onChange={handleInput} value={registerInput.Pais} />
                                </div>
                                <div className="superficie">
                                    <h2 id='Domicilio-ad'>Provincia</h2>
                                    <input type="text" name="Provincia" placeholder="Su Provincia" onChange={handleInput} value={registerInput.Provincia} />
                                </div>

                            </div>
                            <div className="superficies">

                                <div className="superficie">
                                    <h2>Domicilio</h2>
                                    <input type="text" name="Domicilio" id="" placeholder="Su Domicilio" onChange={handleInput} value={registerInput.Domicilio} />
                                </div>
                                <div className="superficie">
                                    <h2 id='Domicilio-ad'>Ciudad</h2>
                                    <input type="text" name="Ciudad" placeholder="Su Ciudad" onChange={handleInput} value={registerInput.Ciudad} />
                                </div>

                            </div>

                            <div className="superficies">

                                <div className="superficie">
                                    <h2>Codigo Postal</h2>
                                    <input type="text" name="Codigo_Postal" id="" placeholder="Ex: 18002" onChange={handleInput} value={registerInput.Codigo_Postal} />
                                </div>
                                <div className="superficie">

                                </div>
                            </div>

                            <input type="submit" className="send-contact" id="admin-create-house-button" value="Añadir" />


                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default RegisterContacto;