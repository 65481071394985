import React, { Component, useEffect, useState } from "react";
import Sidebar from '../../components/sidebar';
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import moment from 'moment';
import "../../styles/admin-contactos.css";
import Adminsidebar from "../../components/admin-sidebar";


function Adminmensajes() {

    const [mensajes, setMensajes] = useState([]);
    const [demo, setDemo] = useState([]);
    const history = useHistory()



    useEffect(() => {
        async function users() {
            const res = await axios.get('/api/admin/mensajes/index');
            if (res.data.status === 200) {
                setMensajes(res.data.mensajes)
            }
        }
        users();
    }, [])

    useEffect(() => {
        async function users() {
            const res = await axios.get('/api/admin/demo/index');
            if (res.data.status === 200) {
                setDemo(res.data.demo)
            }
        }
        users();
    }, [])




    const deletePost = async (e, id) => {
        const deletedPost = e.currentTarget;
        deletedPost.innerText = "Deleting";
        const res = await axios.delete(`api/admin/delete/mensajes/${id}`);
        if (res.data.status === 200) {
            deletedPost.closest('tr').remove();
        }
        history.push('/admin/mensajes')
    }

    const deleteDemo = async (e, id) => {
        const deletedPost = e.currentTarget;
        deletedPost.innerText = "Deleting";
        const res = await axios.delete(`api/admin/delete/demo/${id}`);
        if (res.data.status === 200) {
            deletedPost.closest('tr').remove();
        }
        history.push('/admin/mensajes')
    }

    return (

        <>
            <Adminsidebar />
            <section className="tables">

                <table id="admin-table" className="table table-hover table-light">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Apellido</th>
                            <th scope="col">Email</th>
                            <th scope="col">Telefono</th>
                            <th scope="col">Cuando</th>
                            <th scope="col">Ver</th>
                            <th scope="col">Borrar</th>

                        </tr>
                    </thead>
                    <tbody>
                        {mensajes.map((item) => {
                            return (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.Nombre}</td>
                                    <td>{item.Apellido}</td>
                                    <td>{item.Email}</td>
                                    <td>{item.Telefono}</td>
                                    <td>{moment(item.created_at).fromNow()}</td>
                                    <td>
                                        <Link to={`/admin/mensajes/show/${item.id}`} id="create" className="btn btn-success btn-sm">Ver</Link>
                                    </td>
                                    <td>
                                        <button type='button' id="create" onClick={(e) => deletePost(e, item.id)} className='btn btn-danger btn-sm'>Borrar</button>
                                    </td>
                                </tr>)
                        })
                        }


                    </tbody>
                </table>

                <table id="admin-table" className="table table-hover table-light">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Apellido</th>
                            <th scope="col">Email</th>
                            <th scope="col">Telefono</th>
                            <th scope="col">Cuando</th>
                            <th scope="col">Borrar</th>

                        </tr>
                    </thead>
                    <tbody>
                        {demo.map((item) => {
                            return (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.Nombre}</td>
                                    <td>{item.Apellido}</td>
                                    <td>{item.Email}</td>
                                    <td>{item.Telefono}</td>
                                    <td>{moment(item.created_at).fromNow()}</td>
                                   
                                    <td>
                                        <button type='button' id="create" onClick={(e) => deleteDemo(e, item.id)} className='btn btn-danger btn-sm'>Borrar</button>
                                    </td>
                                </tr>)
                        })
                        }


                    </tbody>
                </table>
            </section>
        </>
    )
}

export default Adminmensajes;