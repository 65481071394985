import React, { Component, useEffect, useState } from "react";
import Sidebar from '../../components/sidebar';
import axios from "axios";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from 'moment';
import "../../styles/admin-contactos.css";
import Adminsidebar from "../../components/admin-sidebar";


function Admincontactos() {

    const [contactos, setContactos] = useState([]);
    const history = useHistory();



    useEffect(() => {
        async function contactos() {
            const res = await axios.get('/api/contactos/index');
            if (res.data.status === 200) {
                setContactos(res.data.contactos)
            }
        }
        contactos();
    }, [])



    const deletePost = async (e, id) => {
        const deletedPost = e.currentTarget;
        deletedPost.innerText = "Deleting";
        const res = await axios.delete(`/api/admin/delete/contacto/${id}`);
        if (res.data.status === 200) {
            deletedPost.closest('tr').remove();
        }
        history.push('/admin/contactos')
    }


    return (

        <>
            <Adminsidebar />
            <section className="tables">
                <Link to="/admin/contactos/create"><button type="button" id="create" class="btn btn-primary">Crear</button></Link>

                <table id="admin-table" className="table table-hover table-light">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Empresa</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Dni</th>
                            <th scope="col">Movil</th>
                            <th scope="col">Fecha</th>
                            <th scope="col">Ver</th>
                            <th scope="col">Editar</th>
                            <th scope="col">Borrar</th>
                        </tr>
                    </thead>

                    <tbody>
                        {contactos.map((item) => {
                            return (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.juridica.Nombre_Fiscal}</td>
                                    <td>{item.Nombre}</td>
                                    <td>{item.DNI}</td>
                                    <td>{item.Telefono_Movil}</td>
                                    <td>{moment(item.created_at).fromNow()}</td>
                                    <td>
                                        <Link to={`/admin/contactos/show/${item.id}`} id="create" className="btn btn-success btn-sm">Ver</Link>
                                    </td>
                                    <td>
                                        <Link to={`/admin/contactos/edit/${item.id}`} id='create' className="btn btn-info btn-sm approved">Edit</Link>
                                    </td>
                                    <td>
                                        <button type='button' id="create" onClick={(e) => deletePost(e, item.id)} className='btn btn-danger btn-sm'>Borrar</button>
                                    </td>
                                </tr>)
                        })
                        }


                    </tbody>
                </table>
            </section>
        </>
    )
}

export default Admincontactos;