import React, { useState } from "react";
import message from "../assets/icons/message.png";
import location from "../assets/icons/location.png";
import call from "../assets/icons/call.png";
import circle from "../assets/images/homepage-9.png";
import "../assets/images/homepage-9.png";
import "../styles/contact.css";
import axios from "axios";
import swal from "sweetalert";
import { useHistory, Link } from "react-router-dom";

const Contact = () => {
  const [registerInput, setregister] = useState({
    Nombre: "",
    Apellido: "",
    Telefono: "",
    Email: "",
    Mensaje: "",
    error_list: [],
  });

  const history = useHistory();

  const handleInput = (e) => {
    setregister({ ...registerInput, [e.target.name]: e.target.value });
  };

  const registerSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();

    data.append("Nombre", registerInput.Nombre);
    data.append("Apellido", registerInput.Apellido);
    data.append("Email", registerInput.Email);
    data.append("Telefono", registerInput.Telefono);
    data.append("Mensaje", registerInput.Mensaje);

    await axios.post("api/create/mensaje", data).then((res) => {
      if (res.data.status === 200) {
        swal({
          title: "Mensaje enviado",
          text: "Le contactaremos lo antes posible",
          icon: "success",
          button: "Continuar",
        });
        setregister({
          Nombre: "",
          Apellido: "",
          Email: "",
          Telefono: "",
          Mensaje: "",
        });
        history.push("/");
      } else {
        setregister({
          ...registerInput,
          error_list: res.data.validation_errors,
        });
      }
    });
  };

  const [count, setCount] = React.useState(0);

  return (
    <>
      <section className="s4">
        <div className="s4-title">
          <h2>Contáctanos</h2>
          <h3 className="s4-content">
            ¿Tienes dudas? ¡Escríbenos un mensaje y te contestaremos lo más
            pronto posible o mándanos un Whatsapp!
          </h3>
        </div>
        <div className="s4-content">
          <div className="form-container">
            <div className="contact-left">
              <div className="contact-left-title">
                <h2>Información de contacto</h2>
              </div>
              <div className="contact-left-details">
                <div className="contact-left-details-info">
                  <img src={call} alt="call" />
                  <h2>+34 692 68 24 46</h2>
                </div>
                <div className="contact-left-details-info">
                  <img src={message} alt="message" />
                  <h2>info@wedibo.es</h2>
                </div>
                <div className="contact-left-details-info">
                  <img src={location} alt="location" />
                  <h2>C/ Juan Carlos I, 55 28609 Sevilla la Nueva (Madrid)</h2>
                </div>
              </div>
              <img src={circle} className="circle-img" alt="circle" />
            </div>
            <div className="contact-right">
              <form onSubmit={registerSubmit}>
                <div className="contact-names">
                  <div className="contact-name">
                    <h2>Nombre</h2>
                    <input
                      type="text"
                      name="Nombre"
                      id=""
                      placeholder="Nombre"
                      onChange={handleInput}
                      value={registerInput.Nombre}
                    />
                    <span className="text-danger">
                      {registerInput.error_list.Nombre}
                    </span>
                  </div>
                  <div className="contact-surname">
                    <h2>Apellidos</h2>
                    <input
                      type="text"
                      name="Apellido"
                      id=""
                      placeholder="Apellidos"
                      onChange={handleInput}
                      value={registerInput.Apellido}
                    />
                    <span className="text-danger">
                      {registerInput.error_list.Apellido}
                    </span>
                  </div>
                </div>
                <div className="contact-email">
                  <h2>Email</h2>
                  <input
                    type="email"
                    name="Email"
                    id=""
                    placeholder="Email"
                    onChange={handleInput}
                    value={registerInput.Email}
                  />
                  <span className="text-danger">
                    {registerInput.error_list.Email}
                  </span>
                </div>
                <div className="contact-phone">
                  <h2>Teléfono</h2>
                  <input
                    type="tel"
                    name="Telefono"
                    id=""
                    placeholder="Teléfono"
                    onChange={handleInput}
                    value={registerInput.Telefono}
                  />
                  <span className="text-danger">
                    {registerInput.error_list.Telefono}
                  </span>
                </div>

                <div className="contact-message">
                  <h2>Mensaje</h2>
                  <textarea
                    type="text"
                    name="Mensaje"
                    id="message"
                    placeholder="Escriba aquí su mensaje"
                    onChange={handleInput}
                    onInput={(e) => setCount(e.target.value.length)}
                    value={registerInput.Mensaje}
                    maxLength="1000"
                    countLimit={1000}
                  />
                  <p className="textarea-count">{count}/1000</p>
                  <span className="text-danger">
                    {registerInput.error_list.Mensaje}
                  </span>
                </div>
                <div className="check-marketing">
                  <input type="checkbox" required id="scales" name="scales" />
                  <label for="scales" className="label-marketing">
                    He leído y acepto la{" "}
                    <Link to="/Privacy-policy">
                      <b className="politica">política de privacidad</b>
                    </Link>
                    .
                  </label>
                </div>
                <div className="contact-names" id="contact-button">
                  <button type="submit">Enviar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
