import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import "../styles/plans.css";
import check from "../assets/icons/check.png";
import cross from "../assets/icons/cross.png";
import basic from "../assets/images/plan_basico.png";
import home7 from "../assets/images/homepage-7.png";
import { Player, BigPlayButton, ControlBar } from "video-react";
import "video-react/dist/video-react.css";
import swal from "sweetalert";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Plans = () => {
  const history = useHistory();

  const Subscription = async (e) => {
    e.preventDefault();
    const amountInput = e.target.amount.value; // Get the input value
    const amount = parseInt(amountInput.replace(/[^\d.]/g, ""), 10) * 100; // Extract only the numbers, convert to an integer, and multiply by 100

    // Verificar si el usuario está autenticado
    const isLoggedIn =
      localStorage.getItem("auth_name") !== null &&
      localStorage.getItem("auth_name") !== "";

    if (!isLoggedIn) {
      // El usuario no está autenticado, redirige a la página de inicio de sesión
      swal({
        title: "Acceso denegado",
        text: "Debes iniciar sesión para realizar esta acción",
        icon: "warning",
        confirmButtonText: "Ir a iniciar sesión",
      }).then(() => {
        history.push("/Login");
      });
    } else {
      await axios
        .post(process.env.REACT_APP_PUBLIC_URL + "/api/createSubscriptionM", {
          amount: amount, // Include the 'amount' value in the request payload
        })
        .then((res) => {
          if (res.data.status === 200) {
            console.log(res.data);

            axios
              .post(
                process.env.REACT_APP_PUBLIC_URL +
                  `/api/activateSubscription/${res.data.data.id}`
              )
              .then((res) => {
                if (res.data.status === 200) {
                  window.open(res.data.data.nextAction.redirectUrl);
                }
              });
          }
        });
    }
  };

  const activateSubscription = async (e) => {
    e.preventDefault();

    const amountInput = e.target.amount.value; // Get the input value
    const amount = parseInt(amountInput.replace(/[^\d.]/g, ""), 10) * 100; // Extract only the numbers, convert to an integer, and multiply by 100

    const isLoggedIn =
    localStorage.getItem("auth_name") !== null &&
    localStorage.getItem("auth_name") !== "";

    if (!isLoggedIn) {
      // El usuario no está autenticado, redirige a la página de inicio de sesión
      swal({
        title: "Acceso denegado",
        text: "Debes iniciar sesión para realizar esta acción",
        icon: "warning",
        confirmButtonText: "Ir a iniciar sesión",
      }).then(() => {
        history.push("/Login");
      });
    } else {
      await axios
        .post(process.env.REACT_APP_PUBLIC_URL + "/api/createSubscriptionP", {
          amount: amount, // Include the 'amount' value in the request payload
        })
        .then((res) => {
          if (res.data.status === 200) {
            console.log(res.data);
            axios
              .post(
                process.env.REACT_APP_PUBLIC_URL +
                  `/api/activateSubscription/${res.data.data.id}`
              )
              .then((res) => {
                if (res.data.status === 200) {
                  window.open(res.data.data.nextAction.redirectUrl);
                }
              });
          }
        });
    }
  };

  const [number, setNumber] = useState({
    one: 1,
    two: 1,
    //three: 1,
    sumaOne: 49,
    sumaTwo: 74,
    //sumaThree: 89,
  });
  const [sumaOne, setSumOne] = useState();
  const [sumaTwo, setSumTwo] = useState();
  //const [sumaThree, setSumThree] = useState();
  const { one, two, three } = number;
  useEffect(() => {
    setSumOne(49 + 25 * (Number(one) - 1));
    setSumTwo(74 + 25 * (Number(two) - 1));
    //setSumThree(89 + 45 * (Number(three) - 1));
  }, [number]);

  const handleInput = (event) => {
    const { name, value } = event.target;

    if (name === "one" && value === "0") {
      return; // No actualices el estado si el valor es 0
    }

    setNumber({ ...number, [name]: value });
  };

  if (number.one <= 0) {
    console.error("Menor que cero");
  }
  return (
    <>
      <section className="plan-container">
        <img src={home7} alt="lines7" className="plan-lines" />
        <div className="plan-box">
          <div className="plan-title">
            <div className="plan-price">
              <h3>Desde</h3>
              <h2>49 €</h2>
              <h3>/mes</h3>
            </div>
            <h3 className="price-user">Un usuario</h3>
            <h3 className="price-add">25€ cada usuario adicional</h3>
          </div>
          <div className="plan-type">
            <h4>Plan professional</h4>
          </div>
          <div className="plan-features">
            <div className="boxs">
              <div className="check">
                <img src={check} alt="check" />
              </div>
              <div className="feature">
                <h5>Control de tareas</h5>
              </div>
            </div>
            <div className="boxs">
              <div className="check">
                <img src={check} alt="check" />
              </div>
              <div className="feature">
                <h5>Acceso a documentos de tus clientes</h5>
              </div>
            </div>
            <div className="boxs">
              <div className="check">
                <img src={check} alt="check" />
              </div>
              <div className="feature">
                <h5>Acceso a datos de tus clientes</h5>
              </div>
            </div>
            <div className="boxs">
              <div className="check">
                <img src={check} alt="check" />
              </div>
              <div className="feature">
                <h5>Control sobre las gestion de los empleados</h5>
              </div>
            </div>
            <div className="boxs">
              <div className="check">
                <img src={check} alt="check" />
              </div>
              <div className="feature">
                <h5>Control sobre las gestiones propias</h5>
              </div>
            </div>
            <div className="boxs">
              <div className="check">
                <img src={cross} alt="cross" />
              </div>
              <div className="feature-deactivated">
                <h5>Formación</h5>
              </div>
            </div>
            <div className="boxs">
              <div className="check">
                <img src={cross} alt="cross" />
              </div>
              <div className="feature-deactivated">
                <h5>Consultoría</h5>
              </div>
            </div>
            <div className="boxs">
              <div className="check">
                <img src={cross} alt="cross" />
              </div>
              <div className="feature-deactivated">
                <h5>Migración de datos</h5>
              </div>
            </div>
          </div>
          <form onSubmit={activateSubscription} className="button-display">
            {/*<div className="button-blue">
              <Popup trigger={<h2>Saber más</h2>} modal nested>
                {(close) => (
                  <div className="video">
                    <button className="close" onClick={close}>
                      &times;
                    </button>
                    <Player
                      fluid
                      playsInline
                      poster={basic}
                      src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                    >
                      <ControlBar className="video-bar" />
                      <BigPlayButton position="center" />
                    </Player>
                  </div>
                )}
              </Popup>
                </div>*/}
            <div className="button-yellow">
              <Popup trigger={<h2>Suscribirse</h2>} modal nested>
                {(close) => (
                  <div className="modal">
                    <button className="close" onClick={close}>
                      &times;
                    </button>
                    <div className="popup-container">
                      <div className="popup-header"> Plan professional </div>
                      <div className="popup-main">
                        <div className="popup-info">
                          <div className="plan-features">
                            <div className="boxs">
                              <div className="check">
                                <img src={check} alt="check" />
                              </div>
                              <div className="feature">
                                <h5>Control de tareas</h5>
                              </div>
                            </div>
                            <div className="boxs">
                              <div className="check">
                                <img src={check} alt="check" />
                              </div>
                              <div className="feature">
                                <h5>Acceso a documentos de tus clientes</h5>
                              </div>
                            </div>
                            <div className="boxs">
                              <div className="check">
                                <img src={check} alt="check" />
                              </div>
                              <div className="feature">
                                <h5>Acceso a datos de tus clientes</h5>
                              </div>
                            </div>
                            <div className="boxs">
                              <div className="check">
                                <img src={check} alt="check" />
                              </div>
                              <div className="feature">
                                <h5>
                                  Control sobre las gestion de los empleados
                                </h5>
                              </div>
                            </div>
                            <div className="boxs">
                              <div className="check">
                                <img src={check} alt="check" />
                              </div>
                              <div className="feature">
                                <h5>Control sobre las gestiones propias</h5>
                              </div>
                            </div>
                          </div>
                          <form>
                            <div className="popup-input">
                              <div className="input-left">
                                Introduce la cantidad de usuarios
                              </div>
                              <input
                                type="number"
                                min="0"
                                max="100"
                                className="input-right"
                                onChange={handleInput}
                                onKeyDown={(e) => {
                                  if (e.key === "-" || e.key === "+") {
                                    e.preventDefault();
                                  }
                                  if (e.target.value === "0") {
                                    e.preventDefault();
                                  }
                                }}
                                onKeyUp={(e) => {
                                  if (
                                    e.target.value === "1" &&
                                    (e.key === "Backspace" ||
                                      e.key === "Delete")
                                  ) {
                                    e.preventDefault();
                                    e.target.value = "1";
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value.length === 0) {
                                    e.target.value = "1";
                                  }
                                }}
                                name="one"
                                value={number.one}
                                aria-label="Introduce la cantidad de usuarios"
                                placeholder="1"
                              />
                            </div>
                            <div className="popup-input">
                              <div className="input-left">TOTAL (sin IVA)</div>
                              <input
                                className="input-right"
                                value={sumaOne + " €/mes"}
                                readOnly
                              />
                            </div>
                            <div className="popup-input">
                              <div className="input-left">TOTAL (con IVA)</div>
                              <input
                                className="input-right"
                                value={(sumaOne * 1.21).toFixed(2) + " €/mes"}
                                readOnly
                                name="amount"
                              />
                            </div>
                            <button type="submit" className="input-button">
                              Continuar
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Popup>
            </div>
          </form>
        </div>
        <div className="plan-box">
          <div className="plan-title">
            <div className="plan-price">
              <h3>Desde</h3>
              <h2>74 €</h2>
              <h3>/mes</h3>
            </div>
            <h3 className="price-user">Un usuario</h3>
            <h3 className="price-add">25€ cada usuario adicional</h3>
          </div>
          <div className="plan-type">
            <h4>Plan enterprise</h4>
          </div>
          <div className="plan-features">
            <div className="boxs">
              <div className="check">
                <img src={check} alt="check" />
              </div>
              <div className="feature">
                <h5>Control de tareas</h5>
              </div>
            </div>
            <div className="boxs">
              <div className="check">
                <img src={check} alt="check" />
              </div>
              <div className="feature">
                <h5>Acceso a documentos de tus clientes</h5>
              </div>
            </div>
            <div className="boxs">
              <div className="check">
                <img src={check} alt="check" />
              </div>
              <div className="feature">
                <h5>Acceso a datos de tus clientes</h5>
              </div>
            </div>
            <div className="boxs">
              <div className="check">
                <img src={check} alt="check" />
              </div>
              <div className="feature">
                <h5>Control sobre las gestion de los empleados</h5>
              </div>
            </div>
            <div className="boxs">
              <div className="check">
                <img src={check} alt="check" />
              </div>
              <div className="feature">
                <h5>Control sobre las gestiones propias</h5>
              </div>
            </div>
            <div className="boxs">
              <div className="check">
                <img src={check} alt="check" />
              </div>
              <div className="feature">
                <h5>Formación</h5>
              </div>
            </div>
            <div className="boxs">
              <div className="check">
                <img src={check} alt="check" />
              </div>
              <div className="feature">
                <h5>Consultoría</h5>
              </div>
            </div>
            <div className="boxs">
              <div className="check">
                <img src={check} alt="check" />
              </div>
              <div className="feature">
                <h5>Migración de datos</h5>
              </div>
            </div>
          </div>
          <form onSubmit={activateSubscription} className="button-display">
            <div className="button-yellow">
              <Popup trigger={<h2>Suscribirse</h2>} modal nested>
                {(close) => (
                  <div className="modal">
                    <button className="close" onClick={close}>
                      &times;
                    </button>
                    <div className="popup-container">
                      <div className="popup-header"> Plan enterprise </div>
                      <div className="popup-main">
                        <div className="popup-info">
                          <div className="popup-features">
                            <div className="popup-left">
                              <div className="boxs">
                                <div className="check">
                                  <img src={check} alt="check" />
                                </div>
                                <div className="feature">
                                  <h5>Control de tareas</h5>
                                </div>
                              </div>
                              <div className="boxs">
                                <div className="check">
                                  <img src={check} alt="check" />
                                </div>
                                <div className="feature">
                                  <h5>Acceso a documentos de tus clientes</h5>
                                </div>
                              </div>
                              <div className="boxs">
                                <div className="check">
                                  <img src={check} alt="check" />
                                </div>
                                <div className="feature">
                                  <h5>Acceso a datos de tus clientes</h5>
                                </div>
                              </div>
                              <div className="boxs">
                                <div className="check">
                                  <img src={check} alt="check" />
                                </div>
                                <div className="feature">
                                  <h5>
                                    Control sobre las gestion de los empleados
                                  </h5>
                                </div>
                              </div>
                            </div>
                            <div className="popup-right">
                              <div className="boxs">
                                <div className="check">
                                  <img src={check} alt="check" />
                                </div>
                                <div className="feature">
                                  <h5>Control sobre las gestiones propias</h5>
                                </div>
                              </div>
                              <div className="boxs">
                                <div className="check">
                                  <img src={check} alt="check" />
                                </div>
                                <div className="feature">
                                  <h5>Formación</h5>
                                </div>
                              </div>
                              <div className="boxs">
                                <div className="check">
                                  <img src={check} alt="check" />
                                </div>
                                <div className="feature">
                                  <h5>Consultoría</h5>
                                </div>
                              </div>
                              <div className="boxs">
                                <div className="check">
                                  <img src={check} alt="check" />
                                </div>
                                <div className="feature">
                                  <h5>Migración de datos</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <form onSubmit={Subscription}>
                            <div className="popup-input">
                              <div className="input-left">
                                Introduce la cantidad de usuarios
                              </div>
                              <input
                                type="number"
                                min="1"
                                max="100"
                                className="input-right"
                                onChange={handleInput}
                                name="two"
                                value={number.two}
                                aria-label="Introduce la cantidad de usuarios"
                                placeholder="1"
                                onKeyDown={(e) => {
                                  if (e.key === "-" || e.key === "+") {
                                    e.preventDefault();
                                  }
                                  if (e.target.value === "0") {
                                    e.preventDefault();
                                  }
                                }}
                                onKeyUp={(e) => {
                                  if (
                                    e.target.value === "1" &&
                                    (e.key === "Backspace" ||
                                      e.key === "Delete")
                                  ) {
                                    e.preventDefault();
                                    e.target.value = "1";
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value.length === 0) {
                                    e.target.value = "1";
                                  }
                                }}
                              />
                            </div>
                            <div className="popup-input">
                              <div className="input-left">TOTAL (sin IVA)</div>
                              <input
                                className="input-right"
                                value={sumaTwo + " €/mes"}
                                readOnly
                              />
                            </div>
                            <div className="popup-input">
                              <div className="input-left">TOTAL (con IVA)</div>
                              <input
                                className="input-right"
                                value={(sumaTwo * 1.21).toFixed(2) + " €/mes"}
                                readOnly
                                name="amount"
                              />
                            </div>
                            <button type="submit" className="input-button">
                              Continuar
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Popup>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Plans;
