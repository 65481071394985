import {React, useLayoutEffect} from "react"
import Navbar from "../../components/navbar";
import logo from "../../assets/logo/logo.png";
import { Link } from "react-router-dom";
import "../../styles/legal.css";
import reunion from "../../assets/images/andrea.jpg";
import Footer from "../../components/footer";
import Sidebar from "../../components/sidebar";

const Legal_advice = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Sidebar />
      <section className="">
        <header>
          <div className="container-home">
            <div>
              <img className="logo" src={logo} alt="logo" />
            </div>
            <Navbar />
          </div>
        </header>
      </section>
      <section className="s2-legal">
        <h1 className="s2-legal-title">
          LEY DE LOS SERVICIOS DE LA SOCIEDAD DE LA INFORMACIÓN(LSSI)
        </h1>
        <div>
          <h4 className="h4-legal">
            DATA INSURANCE COMPANY, S.L., responsable del sitio web, en adelante
            RESPONSABLE, pone a disposición de los usuarios el presente
            documento, con el que pretende dar cumplimiento a las obligaciones
            dispuestas en la Ley 34/2002, de 11 de julio, de Servicios de la
            Sociedad de la Información y de Comercio Electrónico (LSSICE), BOE N
            º 166, así como informar a todos los usuarios del sitio web respecto
            a cuáles son las condiciones de uso.
          </h4>

          <h4 className="h4-legal">
            Toda persona que acceda a este sitio web asume el papel de usuario,
            comprometiéndose a la observancia y cumplimiento riguroso de las
            disposiciones aquí dispuestas, así como a cualquier otra disposición
            legal que fuera de aplicación.
          </h4>

          <h4 className="h4-legal">
            DATA INSURANCE COMPANY, S.L. se reserva el derecho de modificar
            cualquier tipo de información que pudiera aparecer en el sitio web,
            sin que exista obligación de preavisar o poner en conocimiento de
            los usuarios dichas obligaciones, entendiéndose como suficiente con
            la publicación en el sitio web de DATA INSURANCE COMPANY, S.L..
          </h4>

          <h2 className="s2-legal-title">
            <br />
            1. DATOS IDENTIFICATIVOS
          </h2>

          <h4 className="h4-legal">
            Nombre de dominio:&nbsp;
            <a href="http://wedibo.es" target="_blank">
              wedibo.es
            </a>
          </h4>
          <h4 className="h4-legal">Nombre comercial: DATA INSURANCE COMPANY, S.L.</h4>
          <h4 className="h4-legal">Denominación social: DATA INSURANCE COMPANY, S.L.</h4>
          <h4 className="h4-legal">NIF: B09911496</h4>
          <h4 className="h4-legal">
            Domicilio social: C/ Juan Carlos I, 55 - 28609 Sevilla la Nueva
            (Madrid)
          </h4>
          <h4 className="h4-legal">Teléfono: 600508333</h4>
          <h4 className="h4-legal">e-mail: info@wedibo.es</h4>
          <h4 className="h4-legal">Inscrita en el Registro (Mercantil / Público):</h4>
        </div>
        <div />
        <div>
          <h2 className="s2-legal-title">
            2. DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL
          </h2>
        </div>
        <div />
        <div>
          <h4 className="h4-legal">
            El sitio web, incluyendo a título enunciativo pero no limitativo su
            programación, edición, compilación y demás elementos necesarios para
            su funcionamiento, los diseños, logotipos, texto y/o gráficos, son
            propiedad del RESPONSABLE o, si es el caso, dispone de licencia o
            autorización expresa por parte de los autores. Todos los contenidos
            del sitio web se encuentran debidamente protegidos por la normativa
            de propiedad intelectual e industrial, así como inscritos en los
            registros públicos correspondientes.
          </h4>
          <h4 className="h4-legal">
            Independientemente de la finalidad para la que fueran destinados, la
            reproducción total o parcial, uso, explotación, distribución y
            comercialización, requiere en todo caso de la autorización escrita
            previa por parte del RESPONSABLE. Cualquier uso no autorizado
            previamente se considera un incumplimiento grave de los derechos de
            propiedad intelectual o industrial del autor.
          </h4>
          <h4 className="h4-legal">
            Los diseños, logotipos, texto y/o gráficos ajenos al RESPONSABLE y
            que pudieran aparecer en el sitio web, pertenecen a sus respectivos
            propietarios, siendo ellos mismos responsables de cualquier posible
            controversia que pudiera suscitarse respecto a los mismos. El
            RESPONSABLE autoriza expresamente a que terceros puedan redirigir
            directamente a los contenidos concretos del sitio web, y en todo
            caso redirigir al sitio web principal de&nbsp;
            <a href="http://wedibo.es" target="_blank">
              wedibo.es
            </a>
            .
          </h4>
          <h4 className="h4-legal">
            El RESPONSABLE reconoce a favor de sus titulares los
            correspondientes derechos de propiedad intelectual e industrial, no
            implicando su sola mención o aparición en el sitio web la existencia
            de derechos o responsabilidad alguna sobre los mismos, como tampoco
            respaldo, patrocinio o recomendación por parte del mismo.
            <br />
            <br />
            Para realizar cualquier tipo de observación respecto a posibles
            incumplimientos de los derechos de propiedad intelectual o
            industrial, así como sobre cualquiera de los contenidos del sitio
            web, puede hacerlo a través del correo electrónico
            info@wedibo.es.
          </h4>
          <br />
        </div>

        <h2 className="s2-legal-title">3. EXENCIÓN DE RESPONSABILIDADES</h2>

        <h4 className="h4-legal">
          El RESPONSABLE se exime de cualquier tipo de responsabilidad derivada
          de la información publicada en su sitio web siempre que esta
          información haya sido manipulada o introducida por un tercero ajeno al
          mismo.
        </h4>

        <h3 className="s2-legal-title">Política de enlaces</h3>
        <div>
          <h4 className="h4-legal">
            Desde el sitio web, es posible que se redirija a contenidos de
            terceros sitios web. Dado que el RESPONSABLE no puede controlar
            siempre los contenidos introducidos por los terceros en sus
            respectivos sitios web, no asume ningún tipo de responsabilidad
            respecto a dichos contenidos. En todo caso, procederá a la retirada
            inmediata de cualquier contenido que pudiera contravenir la
            legislación nacional o internacional, la moral o el orden público,
            procediendo a la retirada inmediata de la redirección a dicho sitio
            web, poniendo en conocimiento de las autoridades competentes el
            contenido en cuestión.
          </h4>
          <h4 className="h4-legal">
            El RESPONSABLE no se hace responsable de la información y contenidos
            almacenados, a título enunciativo pero no limitativo, en foros,
            chats, generadores de blogs, comentarios, redes sociales o cualquier
            otro medio que permita a terceros publicar contenidos de forma
            independiente en la página web del RESPONSABLE. Sin embargo, y en
            cumplimiento de lo dispuesto en los artículos 11 y 16 de la LSSICE,
            se pone a disposición de todos los usuarios, autoridades y fuerzas
            de seguridad, colaborando de forma activa en la retirada o, en su
            caso, bloqueo de todos aquellos contenidos que puedan afectar o
            contravenir la legislación nacional o internacional, los derechos de
            terceros o la moral y el orden público. En caso de que el usuario
            considere que existe en el sitio web algún contenido que pudiera ser
            susceptible de esta clasificación, se ruega lo notifique de forma
            inmediata al administrador del sitio web.
          </h4>
          <h4 className="h4-legal">
            Este sitio web ha sido revisado y probado para que funcione
            correctamente. En principio, puede garantizarse el correcto
            funcionamiento los 365 días del año, 24 horas al día. Sin embargo,
            el RESPONSABLE no descarta la posibilidad de que existan ciertos
            errores de programación, o que acontezcan causas de fuerza mayor,
            catástrofes naturales, huelgas o circunstancias semejantes que hagan
            imposible el acceso a la página web.
          </h4>
          <br />
          <h3 className="s2-legal-title">Direcciones IP</h3>
        </div>
        <h4 className="h4-legal">
          Los servidores del sitio web podrán detectar de manera automática la
          dirección IP y el nombre de dominio utilizados por el usuario. Una
          dirección IP es un número asignado automáticamente a un ordenador
          cuando éste se conecta a Internet. Toda esta información es registrada
          en un fichero de actividad del servidor debidamente inscrito que
          permite el posterior procesamiento de los datos con el fin de obtener
          mediciones únicamente estadísticas que permitan conocer el número de
          impresiones de páginas, el número de visitas realizadas a los
          servidores web, el orden de visitas, el punto de acceso, etc.
        </h4>
        <h2 className="s2-legal-title">4. LEY APLICABLE Y JURISDICCIÓN</h2>
        <h4 className="h4-legal">
          Para la resolución de todas las controversias o cuestiones
          relacionadas con el presente sitio web o de las actividades en él
          desarrolladas, será de aplicación la legislación española, a la que se
          someten expresamente las partes, siendo competentes para la resolución
          de todos los conflictos derivados o relacionados con su uso los
          Juzgados y Tribunales del domicilio de los usuarios afectados.
        </h4>
      </section>

      <Footer />
    </>
  );
};

export default Legal_advice;
