import React from 'react';
import "../styles/footer.css";
import logo from '../assets/logo/logo.png';
import facebook from "../assets/icons/facebook.png"
import instagram from "../assets/icons/instagram.png"
import link from "../assets/icons/link.png"
import email from "../assets/icons/email.png"
import twitter from "../assets/icons/twitter.png"
import { Link } from 'react-router-dom';


const Footer = () => {
    return(
        <>
        <footer>
            <div className="fot-content">
                <div className="fot-left">
                    <img src={logo} alt="" />
                </div>
                <div className="fot-right">
                    <div className="fot-container">
                        <h2>Legal</h2>
                        <Link to="/Privacy-policy"><h3>Política de privacidad</h3></Link>
                        <Link to="/Legal-advice"><h3>Aviso legal y condiciones</h3></Link>
                        <Link to="/Cookies-policy"><h3>Política sobre cookies</h3></Link>
                    </div>
                    <div className="fot-container">
                        <h2>Nuestra empresa</h2>
                        <Link to="/About"><h3>Sobre nosotros</h3></Link>
                        <Link to="/Contact"><h3>Contáctanos</h3></Link>
                    </div>
                    
                    <div className="fot-container">
                        <h2>Únete a nosotros</h2>
                        <div className="fot-container-img">
                            <a href="mailto:info@wedibo.es" target="_blank" ><img src={email} alt="email" /></a>
                            <a href="https://www.linkedin.com/company/wedibo" target="_blank" ><img src={link} alt="linkedin" /></a>
                            <a href="https://www.facebook.com/WediboOficial" target="_blank" ><img src={facebook} alt="facebook" /></a>
                            <a href="https://www.instagram.com/Wedibo_Oficial" target="_blank" ><img src={instagram} alt="instagram" /></a>
                            <a href="https://www.twitter.com/Wedibo_Oficial" target="_blank" ><img src={twitter} alt="twitter" /></a>
                        </div>
                    </div>

                </div>
            </div>
            <div className="fot-copyright">
                <h2>Copyright 2023 © Data Insurance Company S.L. - Todos los derechos reservados</h2>
            </div>
        </footer>
        </>
    )
}

export default Footer;