import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import swal from "sweetalert";
import '../../styles/register-juridica.css';



function EditContacto() {
    const { id } = useParams();
    const user_id = id;
    const history = useHistory()
    const [changeInput, setChange] = useState({
        Contacto_Juridico: "",
        Nombre: "",
        DNI: "",
        Telefono_Movil: "",
        Email: "",
        Pais: "",
        Provincia: "",
        Domicilio: "",
        Codigo_Postal: "",
        Ciudad: "",
        error_list: [],
    });

    const handleInput = (e) => {
        setChange({ ...changeInput, [e.target.name]: e.target.value });
    }

    // const handleOption = (option) => {
    //     setOption('');
    // };

    // const handleImage = (files) => {
    //     setImagedata(files);
    // };

    // for (let i = 0; i < imagedata.length; i++)
    //     data.append(`imagenes[${i}]`, imagedata[i], imagedata[i].name)

    useEffect(() => {
        async function test() {

            const res = await axios.get(`/api/admin/edit/contacto/${user_id}`);
            if (res.data.status === 200) {
                setChange({
                    Contacto_Juridico: res.data.contacto.juridica.Nombre_Fiscal,
                    Nombre: res.data.contacto.Nombre,
                    DNI: res.data.contacto.DNI,
                    Telefono_Movil: res.data.contacto.Telefono_Movil,
                    Email: res.data.contacto.Email,
                    Pais: res.data.contacto.Pais,
                    Provincia: res.data.contacto.Provincia,
                    Domicilio: res.data.contacto.Domicilio,
                    Codigo_Postal: res.data.contacto.Codigo_Postal,
                    Ciudad: res.data.contacto.Ciudad,

                })
            }
        }
        test()
    }, [])

    const Update = async (e) => {
        e.preventDefault();

        const res = await axios.put(`api/admin/update/contacto/${user_id}`, changeInput);
        if (res.data.status === 200) {

            swal({
                title: "Bien Hecho !",
                text: "Has editado un contacto juridico !",
                icon: "success",
                button: "Continuar...",
            });
            history.push("/admin/contactos");



        }
    }


    return (
        <>
            <section className="form-creates">
                <div className="contact-forms" id="create-admin">
                    <form onSubmit={Update} className="form-house-create" id="create-admin-form">
                        <div className="form-lefts">
                            <div className="superficies">
                                <div className="superficie">
                                    <h2>Empresa</h2>
                                    <input type="text" disabled name="Contacto_Juridico" placeholder="Nombre Fiscal" id='profile-input' onChange={handleInput} value={changeInput.Contacto_Juridico} />

                                </div>

                                <div className="superficie">
                                    

                                </div>



                            </div>


                            <div className="superficies">
                                <div className="superficie">
                                    <h2>Nombre</h2>
                                    <input type="text" name="Nombre" id='profile-input'  placeholder="Nombre" onChange={handleInput} value={changeInput.Nombre} />
                                </div>
                                <div className="superficie">
                                    <h2>DNI</h2>
                                    <input type="text" name="DNI" id='profile-input'  placeholder="Su DNI" onChange={handleInput} value={changeInput.DNI} />
                                </div>

                            </div>

                            <div className="superficies">

                                <div className="superficie">
                                    <h2>Email</h2>
                                    <input type="email" name="Email"  id='profile-input' placeholder="Email" onChange={handleInput} value={changeInput.Email} />
                                </div>

                                <div className="superficie">
                                    <h2>Telefono Movil</h2>
                                    <input type="text" name="Telefono_Movil"  id='profile-input' placeholder="Su Movil" onChange={handleInput} value={changeInput.Telefono_Movil} />
                                </div>


                            </div>






                        </div>
                        <div className="form-lefts">
                            <div className="superficies">

                                <div className="superficie">
                                    <h2>Pais</h2>
                                    <input type="text" name="Pais"  id='profile-input' placeholder="Su Email" onChange={handleInput} value={changeInput.Pais} />
                                </div>
                                <div className="superficie">
                                    <h2 id='precio-ad'>Provincia</h2>
                                    <input type="text" name="Provincia"  placeholder="Provincia" id='profile-input' onChange={handleInput} value={changeInput.Provincia} />
                                </div>

                            </div>
                            <div className="superficies">

                                <div className="superficie">
                                    <h2>Domicilio</h2>
                                    <input type="text" name="Domicilio"  id='profile-input' placeholder="Domicilio" onChange={handleInput} value={changeInput.Domicilio} />
                                </div>
                                <div className="superficie">
                                    <h2 id='precio-ad'>Ciudad</h2>
                                    <input type="text" name="Ciudad" placeholder="Ciudad" id='profile-input' onChange={handleInput} value={changeInput.Ciudad} />
                                </div>

                            </div>

                            <div className="superficies">

                                <div className="superficie">
                                    <h2>Codigo Postal</h2>
                                    <input type="text" name="Codigo_Postal"  id='profile-input' placeholder="18001" onChange={handleInput} value={changeInput.Codigo_Postal} />
                                </div>


                                <div className="superficie">

                                </div>




                            </div>

                                                        <input type="submit" className="send-contact" id="admin-edit-button" value="Editar" />






                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default EditContacto;