import React from "react";
import whatchat from "../assets/icons/whatchat.png";
import "../styles/whatsapp.css";


const Whatsapp = () => {

    return(
        <>
            <div className="whatsapp-chat">
                <a href="https://wa.me/message/CPWMDK37BB7QF1" target="_blank" className="chat-link">
                    <img src={whatchat} alt="" />
                </a>
            </div>
        </>
    )
}

export default Whatsapp;